<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div
    class="chat-full-screen"
    style="height: inherit"
  >
    <div
      class="body-content-overlay"
      :class="{ show: shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = shallShowActiveChatContactSidebar = shallShowUserProfileSidebar = false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">
      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
          @click="startConversation"
        >
          Start Conversation
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex align-items-center w-100">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :src="activeChat.avatar"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                :badge-variant="resolveAvatarBadgeVariant(activeChat.status)"
                @click.native="shallShowActiveChatContactSidebar = true"
              />
              <div class="d-flex align-items-center justify-content-between w-100">
                <h6 class="mb-0">
                  {{ activeChatPayload.customer_name }}
                </h6>
                <p class="mb-0 user-post text-capitalize">
                  Order Number: {{ activeChatPayload.order_number }}
                </p>
              </div>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area ps-container scrollable-container media-list scroll-area ps ps--active-y auto-flow-scroll"
          style="max-height:70vh"
        >
          <chat-log
            :chat-data="activeChat"
            :profile-user-avatar="profileUserDataMinimal.picture"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Enter your message"
            />
          </b-input-group>
          <b-button
            variant="primary"
            type="submit"
          >
            Send
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeChat || {}"
      :active-contact="activeChatPayload"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :active-chat-contact-id="activeChatPayload ? activeChatPayload.id : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
        @reload-contacts="reloadContacts"
      />
    </portal>
  </div>
</template>

<script>
import eventBus from '@/libs/event-bus'
import store from '@/store'
import { ref, nextTick } from '@vue/composition-api'
import { BAvatar, BForm, BInputGroup, BFormInput, BButton } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import { showErrorNotification, useResponsiveAppLeftSidebarVisibility, showDangerNotification, showLoader, hideLoader } from '@core/comp-functions/ui/app'
import { useStoreUi } from '@/views/store/useStore'
import { useUserUi } from '@/views/users/useUser'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'

export default {
  components: {
    BAvatar,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    VuePerfectScrollbar,
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  data() {
    return {
      chatInputMessage: '',
      chatsContacts: [],
      contacts: [],
    }
  },
  mounted() {
    window.chatRef = this
    this.reloadContacts()
  },
  methods: {
    resolveAvatarBadgeVariant(status) {
      if (status === 'online') return 'success'
      if (status === 'busy') return 'danger'
      if (status === 'away') return 'warning'
      return 'secondary'
    },
    addChatsMessage(formData) {
      return new Promise(resolve => {
        showLoader()
        const { addChats } = useUserUi()

        addChats(formData)
          .then(({ data }) => {
            if (data.statusCode === '200') {
              hideLoader()
            } else {
              showDangerNotification(this, data.message)
            }
            resolve(true)
          })
          .catch(error => {
            resolve(true)
            showErrorNotification(this, error)
          })
      })
    },
    reloadContacts() {
      const { getChatNotifications } = useStoreUi()
      showLoader()
      getChatNotifications()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.chatsContacts = data.responseData
            this.contacts = []
            hideLoader()
          } else {
            showDangerNotification(window.chatRef, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(window.chatRef, error)
        })
    },
  },
  setup() {
    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref(null)
    const activeChatPayload = ref({})
    const openChatOfContact = payload => {
      // Reset send message input value
      window.chatRef.chatInputMessage = ''
      const { getChatsForUser } = useUserUi()
      showLoader()
      getChatsForUser({
        id: payload.request_id,
        type: payload.admin_service,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            activeChatPayload.value = payload
            const userMessages = data.responseData[0]
            if (userMessages) {
              let msgData = []
              userMessages.data.forEach(item => {
                try {
                  if (item && item.message) {
                    const dataPayload = JSON.parse(item.message)
                    dataPayload.picture = item.picture
                    msgData.push(dataPayload)
                  }
                } catch (error) {
                  console.log(error)
                }
              })
              msgData = msgData.filter(x => x.message || x.picture)
              activeChat.value = msgData
            }
            hideLoader()
            // Scroll to bottom
            nextTick(() => {
              scrollToBottomInChatLog()
            })
          } else {
            showDangerNotification(window.chatRef, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(window.chatRef, error)
        })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }
    const sendMessage = async () => {
      if (!window.chatRef.chatInputMessage) return
      const userData = window.SuperApp.getters.userInfo()
      const username = `${userData.store_name}`
      const avatar = userData.picture
      const userTypeInfo = 'SHOP'
      const payload = {
        from: {
          name: username,
          avatar,
          id: userData.id,
          userType: userTypeInfo,
        },
        chatType: 'ORDER',
        message: window.chatRef.chatInputMessage,
        dataId: activeChatPayload.value.request_id,
        time: new Date(),
      }
      const formData = new FormData()
      formData.append('id', activeChatPayload.value.request_id)
      formData.append('admin_service', 'ORDER')
      formData.append('salt_key', window.SuperApp.getters.saltKey())
      formData.append('user_name', activeChatPayload.value.customer_name)
      formData.append('provider_name', username)
      formData.append('type', userTypeInfo)
      formData.append('user_id', activeChatPayload.value.user_id)
      formData.append('store_id', activeChatPayload.value.store_id)
      if (window.chatRef.chatInputMessage.picture) {
        formData.append('picture', window.chatRef.chatInputMessage.picture)
      }
      formData.append('message', JSON.stringify(payload))
      showLoader()
      console.time()
      window.chatRef.chatInputMessage = ''
      await window.chatRef.addChatsMessage(formData)
      console.timeEnd()
      hideLoader()
      setTimeout(() => {
        eventBus.$emit('chat-message', {
          ...payload,
        })
      }, 0)
      if (!activeChat.value) {
        activeChat.value = []
      }
      activeChat.value.push(payload)
      nextTick(() => {
        scrollToBottomInChatLog()
      })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      profileUserData.value = window.SuperApp.getters.userInfo()
      shallShowUserProfileSidebar.value = true
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      // Single Chat
      refChatLogPS,
      activeChat,
      activeChatPayload,
      openChatOfContact,
      sendMessage,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import '~@core/scss/base/pages/app-chat.scss';
@import '~@core/scss/base/pages/app-chat-list.scss';

.chat-full-screen {
  .chat-app-window {
    height: 75vh;
  }
}
</style>